<template>
  <div>
    <div class="text-center mt-3">
      <el-pagination
        background
        @current-change="setPage"
        :page-size="pageSize"
        :current-page="page"
        :total="resultsTotal"
        :pager-count="9"
        layout="prev, pager, next"
      >
      </el-pagination>
    </div>
    <div class="classes-box">
      <div
        class="class-item"
        v-for="(course, index) in classes"
        :key="index"
        style="padding: 0.5rem;"
      >
        <router-link
          :to="{
            name: routerName,
            params: { id: course.id }
          }"
          style="text-decoration: none;"
        >
          <el-card
            class="course-card"
            style="max-width:350px;margin: 0 auto;"
            :body-style="{ padding: '0px' }"
            shadow="hover"
          >
            <div class="card-cover">
              <img
                src="https://ivy-way-toefl.s3.ap-northeast-1.amazonaws.com/toefl/img/lesson/how_to_structure_your_toefl_speaking_question_2.png"
              />
              <div class="block-list-item-info-mask"></div>
              <div class="block-list-item-info">
                <div class="block-list-item-info-player">
                  <i class="far fa-play-circle"></i>
                  <span class="ml-1">26.4萬</span>
                </div>
                <span>共20課時</span>
              </div>
            </div>
            <div style="width: 100%;padding: 0.5rem;">
              <h5 class="class-title">
                {{ course.name }}
              </h5>
              <div class="description">
                {{ course.description }}
              </div>
              <div class="block-list-item-price text-right">
                <del class="block-list-item-price origin">200 Coins</del>
                <span class="block-list-item-price line">|</span>
                <span class="price-pre">100 Coins</span>
              </div>
            </div>
          </el-card>
        </router-link>
      </div>
    </div>
    <div class="text-center mb-3">
      <el-pagination
        background
        @current-change="setPage"
        :page-size="pageSize"
        :current-page="page"
        :total="resultsTotal"
        :pager-count="9"
        layout="prev, pager, next"
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
/*eslint-disable no-unused-vars */
import { mapState, mapGetters, mapActions } from "vuex";
/*eslint-enable */

export default {
  components: {},

  mixins: [],

  props: ["classes", "resultsTotal", "pageSize", "routerName"],
  data() {
    return {};
  },
  computed: {
    page() {
      let page = 1;
      if (this.$route.query.page === undefined) {
        page = 1;
      } else {
        page = this.$route.query.page;
      }
      return parseInt(page);
    }
  },
  watch: {},

  mounted() {},

  methods: {
    setPage(page) {
      this.routerPush({
        params: { ...this.$route.params },
        query: { ...this.$route.query, page: page }
      });
    }
  }
};
</script>

<style scoped>
.course-card {
  cursor: pointer;
}
.card-cover {
  position: relative;
  width: 100%;
  aspect-ratio: 16 / 9;
}
.card-cover img {
  display: inline-block;
  height: 100%;

  width: 100%;
}
.block-list-item-info-mask {
  position: absolute;
  width: 100%;
  height: 3rem;
  bottom: 0;
  left: 0;
  background-image: linear-gradient(180deg, transparent, rgba(0, 0, 0, 0.6));
  border-radius: 0 0 2px 2px;
}
.block-list-item-info {
  display: flex;
  position: absolute;
  width: 100%;
  bottom: 6px;
  left: 0;
  padding: 0 8px;
  box-sizing: border-box;
  -ms-flex-pack: justify;
  justify-content: space-between;
  color: #fff;
  font-size: 0.8rem;
}

.class-item {
  min-width: 10%;
}
.description {
  height: 1rem;
  line-height: 1rem;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  color: #9499a0;
  white-space: pre-line;
  font-size: 0.8rem;
}

.class-title {
  margin-bottom: 0.5rem;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-size: 1rem;
}
.block-list-item-price {
  font-size: 1rem;
  color: var(--themeColor);
  line-height: 1.5rem;
}
.block-list-item-price .line {
  margin: 0 0.3rem;
}
.price-pre {
  font-weight: 500;
}
.block-list-item-price.origin,
.block-list-item-price.line {
  color: #999;
}
.time {
  font-size: 13px;
  color: #999;
}

.bottom {
  margin-top: 13px;
  line-height: 12px;
}

.button {
  padding: 0;
  float: right;
}

.image {
  width: 100%;
  display: block;
}

.clearfix:before,
.clearfix:after {
  display: table;
  content: "";
}

.clearfix:after {
  clear: both;
}

.classes-box {
  display: grid;
}

@media (min-width: 1560px) and (max-width: 2059.9px) and (min-width: 1560px) and (max-width: 2059.9px)
{
  .classes-box {
    grid-template-columns: repeat(5, 1fr);
  }
}

@media (min-width: 1400px) and (max-width: 1559.9px) and (min-width: 1400px) and (max-width: 1559.9px)
{
  .classes-box {
    grid-template-columns: repeat(5,1fr);
  }
}
@media (min-width: 1300px) and (max-width: 1399.9px) and (min-width: 1300px) and (max-width: 1399.9px)
{
  .classes-box {
    grid-template-columns: repeat(4,1fr);
  }
}
@media (min-width: 1140px) and (max-width: 1299.9px) and (min-width: 1140px) and (max-width: 1299.9px)
{
  .classes-box {
    grid-template-columns: repeat(4,1fr);
  }
}
@media (max-width: 1139.9px) and (max-width: 1139.9px)
{
  .classes-box {
    grid-template-columns: repeat(3,1fr);
  }
}
</style>
