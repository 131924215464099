<template>
  <div class="box">
    <SATMenu active="7" />
    <div class="container">
      <h1 class="sat_left">
        <p>
          Courses
        </p>
      </h1>
      <hr />
      <Classes
        :classes="classes"
        :resultsTotal="resultsTotal"
        :pageSize="pageSize"
        routerName="VideoClassesDetail"
      ></Classes>
    </div>
  </div>
</template>

<script>
/*eslint-disable no-unused-vars */
import { mapState, mapGetters, mapActions } from "vuex";
/*eslint-enable */
import SATMenu from "@/views/AllClasses/components/SATMenu.vue";
import Classes from "@/views/videoClasses/components/Classes.vue";
import Class from "@/apis/class.js";

export default {
  metaInfo() {
    return {
      title: `${this.$t("TeacherCourses.My Classes")} - ${this.CompanyName}`
    };
  },

  components: {
    SATMenu,
    Classes
  },

  mixins: [],

  props: [],
  data() {
    return {
      classes: [],
      resultsTotal: 0,
      pageSize: 9
    };
  },
  computed: {},
  watch: {},

  mounted() {
    this.getClasses();
  },

  methods: {
    async getClasses() {
      const res = await Class.getClassesForStudent({
        per_page: this.pageSize,
        page: this.page
      });
      this.classes = res.session_classes.data;
      this.resultsTotal = res.session_classes.total;
    }
  }
};
</script>

<style scoped>
.sat_left {
  margin: 20px 0;
  display: flex;
  color: var(--themeColor);
  text-decoration: none;
  align-items: center;
}

.sat_left > span {
  font-weight: 700;
  font-size: 20px;
}
.sat_left > p {
  font-size: 40px;
  font-weight: 700;
  line-height: 40px;
  margin-bottom: 0px;
}
::v-deep .sat_left a {
  text-decoration: none;
  color: white !important;
}
.box .container {
  padding-top: 0;
}
</style>
